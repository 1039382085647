@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.mainContainer {
  height: auto;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  max-height: 78%;
}
