@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.profileAvt {
  @include RP(
    (
      "height": 40,
      "width": 40,
    )
  );
  border-radius: 100%;
  object-fit: cover;
}

.profileName {
  font-family: var(--font-neue);
  font-weight: 500;

  @include RP(
    (
      "font-size": 16,
      "line-height": 16,
    )
  );
  color: $BLACK;
}

.profileEmail {
  font-family: var(--font-neue);
  font-weight: 400;

  @include RP(
    (
      "font-size": 14,
      "line-height": 12,
    )
  );
  color: $GRAY;
}

.dropDownIcon {
  @include RP(
    (
      "height": 16,
      "width": 16,
    )
  );
}

.optionsContainer {
  top: 40px;
  max-width: 200px;
  right: 0;
  min-width: 200px;
}

.loginBtn {
  font-family: var(--font-neue);
  font-weight: 400;

  @include RP(
    (
      "font-size": 16,
    )
  );
}

.extra {
  height: 40px;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: transparent;
}
