@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

$breakpointMedium: 1199px;

@mixin maxWidth($breakpoint) {
  @if ($breakpoint == "medium") {
    @media (max-width: $breakpointMedium) {
      @content;
    }
  }
}

.logoTopContainerFull {
  min-width: 256px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.logoTopContainerHalf {
  min-width: 72px;
  max-width: 72px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  overflow-x: hidden;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $PRIMARY;
  width: 100%;
  height: 100%;
  svg {
    height: 40px;
    width: 40px;
  }
}

.bigLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $PRIMARY;
  width: 100%;
  height: 100%;
  svg {
    height: 40px;
    width: 40px;
  }
}

.logoContainer {
  @include RP(
    (
      "height": 70,
    )
  );
  .chevIcon {
    height: 16px;
    width: 16px;

    @include maxWidth("medium") {
      display: none;
    }
    cursor: pointer;
  }
}

.navbrand {
  @include maxWidth("medium") {
    display: none;
  }
}

.navBrandHalf {
  width: 36.66px;
  height: 34.94px;

  display: none;
  @include maxWidth("medium") {
    display: inline;
  }
}

.topLevelContainer {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 0px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 200;
}

.headerContainer {
  @include RP(
    (
      "height": 70,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftIcon {
  height: 16px;
  width: 16px;
  display: none;
  &:hover {
    cursor: pointer;
  }
}

.hamburgerIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 576px) {
  .logoTopContainerFull {
    display: none;
  }
  .logoTopContainerHalf {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .customContainer {
    max-width: 100%;
  }
  .logoTopContainerFull {
    width: 72px;
    min-width: 72px;
    display: none;
  }
  .logoContainer {
    justify-content: center;
  }
  .logoTopContainerHalf {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .hamburgerIcon {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .logoTopContainerFull {
    width: 72px;
    min-width: 72px;
    display: flex;
  }
  .logoContainer {
    justify-content: center;
  }
  .logoTopContainerHalf {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .logoTopContainerFull {
    width: 72px;
    min-width: 72px;
    display: flex;
  }
  .logoContainer {
    justify-content: center;
  }
}

@media screen and (min-width: 1200px) {
  .logoTopContainerFull {
    min-width: 256px;
    display: flex;
  }
  .logoContainer {
    justify-content: space-around;
  }
  .bigLogo {
    svg {
      width: 100%;
    }
  }
}

@media screen and (min-width: 1400px) {
  .logoTopContainerFull {
    min-width: 256px;
    display: flex;
  }
}
