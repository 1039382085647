@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

$breakpointMedium: 1199px;

@mixin maxWidth($breakpoint) {
  @if ($breakpoint == "medium") {
    @media (max-width: $breakpointMedium) {
      @content;
    }
  }
}

.navTitle {
  padding-left: 33px;
  color: $GRAY;
  font-family: var(--font-neue);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  @include elipsis(1);
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  @include maxWidth("medium") {
    display: none;
  }
  text-transform: uppercase;
}

.navItemContainer,
.activeNavItemContainer {
  display: flex;
  align-items: center;
  height: 44px;
  justify-content: flex-start;
  padding-left: 30px;
  border-left: 3px solid white;
  @include maxWidth("medium") {
    padding-left: 0px;
    justify-content: center !important;
  }

  .icon {
    margin-right: 12px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;

    @include maxWidth("medium") {
      margin-right: 0px !important;
    }

    height: 100%;
    width: 100%;
    filter: invert(62%) sepia(0%) saturate(4517%) hue-rotate(238deg)
      brightness(90%) contrast(83%);
  }

  .heading {
    color: $GRAY;
    font-family: var(--font-neue);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    @include elipsis(1);
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;

    @include maxWidth("medium") {
      display: none;
    }
  }

  &:hover {
    border-left: 3px solid $SECONDARY;
    .icon {
      filter: invert(10%) sepia(68%) saturate(1979%) hue-rotate(194deg)
        brightness(89%) contrast(104%);
    }
    .heading {
      font-weight: 500;
      color: $SECONDARY;
    }
    cursor: pointer;
  }
}

.activeNavItemContainer {
  border-left: 3px solid $SECONDARY;

  .heading {
    font-weight: 500;
    color: $SECONDARY;
  }

  .icon {
    filter: invert(10%) sepia(68%) saturate(1979%) hue-rotate(194deg)
      brightness(89%) contrast(104%);
  }
}

.sideNavFull {
  width: 256px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.sideNavHalf {
  width: 72px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  .heading {
    display: none;
  }

  .navItemContainer {
    padding-left: 0px;
    justify-content: center !important;

    .icon {
      margin-right: 0px !important;
    }

    .heading {
      display: none;
    }
  }
}

@media screen and (min-width: 576px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 768px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 992px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .sideNavFull {
    width: 256px;
  }
}

@media screen and (min-width: 1400px) {
  .sideNavFull {
    width: 256px;
  }
}
