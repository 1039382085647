@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
@import "pages/auth/login/style.module.scss";

.mainContainer {
  .formContainer {
    @include RP(
      (
        "padding-bottom": 20,
      )
    );
    .backToLogin {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 5px;
      gap: 5px;
      cursor: pointer;
      @include RP(
        (
          "padding-top": 15,
        )
      );
      label {
        cursor: pointer;
        @include RP(
          (
            "font-size": 16,
          )
        );
        color: $PRIMARY;
      }
      svg {
        @include RP(
          (
            "height": 25,
            "width": 25,
          )
        );
        path {
          fill: $PRIMARY;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.primary {
  background-color: $PRIMARY;
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    .leftContainer {
      height: auto !important;
      overflow-y: unset !important;
    }
  }
}
