.h100 {
  height: 100%;
}

@mixin elipsis($val) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $val;
  -webkit-box-orient: vertical;
}

@mixin hideScrollBar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

@function calculateRatio($base-size, $ratio) {
  $result: $base-size * $ratio;
  @if ($result < 0) {
    @return $base-size * $ratio;
  } @else if ($result > 10) {
    @return $base-size * $ratio;
  } @else {
    @return 9;
  }
}

@mixin RP($properties) {
  @include spreadProperty($properties, 1);

  @media screen and (max-width: 640px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 640px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 768px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 992px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 1200px) {
    @include spreadProperty($properties, calc(1200 / 1400));
  }

  @media screen and (min-width: 1400px) {
    @include spreadProperty($properties, calc(1400 / 1400));
  }
}

@mixin MRP($properties) {
  @include spreadProperty($properties, 1);

  @media screen and (max-width: 640px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 640px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 768px) {
    @include spreadProperty($properties, calc(992 / 1400));
  }

  @media screen and (min-width: 992px) {
    @include spreadProperty($properties, calc(1400 / 1400));
  }

  @media screen and (min-width: 1200px) {
    @include spreadProperty($properties, calc(1400 / 1400));
  }

  @media screen and (min-width: 1400px) {
    @include spreadProperty($properties, calc(1400 / 1400));
  }
}

@mixin spreadProperty($myArray, $ratioValue) {
  @each $name, $value in $myArray {
    $key: $name;
    $value: calculateRatio($value, $ratioValue) + px;
    #{$key}: $value;
  }
}

@function calculatePixels($screenSize, $value) {
  $ratio: calc($screenSize/1400);
  $result: $value * $ratio;
  @return $result + px;
}

$small: #{70 * 992/1400}px;
$mid: #{70 * 1200/1400}px;
$large: #{70 * 1400/1400}px;

.containerPadding {
  padding: 0 44px;
  text-align: left;
  padding-top: 24px;

  @media screen and (max-width: 576px) {
    padding: 0 14px;
    padding-top: 20px;
  }

  @media (min-width: 576px) and (max-width: 768px) {
    padding: 0 14px;
    padding-top: 20px;
  }
}

.listContainerStyle {
  width: 100%;
  height: calc(100vh - 70px);
  position: relative;
  overflow: scroll;
  @include hideScrollBar();
}

@media screen and (max-width: 640px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $small);
  }
}

@media screen and (min-width: 640px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $small);
  }
}

@media screen and (min-width: 768px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $small);
  }
}

@media screen and (min-width: 992px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $small);
  }
}

@media screen and (min-width: 1200px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $mid);
  }
}

@media screen and (min-width: 1400px) {
  .listContainerStyle {
    width: 100%;
    height: calc(100vh - $large);
  }
}

.customContainer {
  margin: auto;
}

@media screen and (max-width: 640px) {
  .customContainer {
    max-width: 90%;
  }
}

@media screen and (min-width: 640px) {
  .customContainer {
    max-width: 600px;
  }
}

@media screen and (min-width: 768px) {
  .customContainer {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .customContainer {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .customContainer {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .customContainer {
    max-width: 1320px;
  }
}
