@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.modalActions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.modalActions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modalActions button:first-child {
  background-color: #dc3545;
  color: white;
}

.modalActions button:last-child {
  background-color: #6c757d;
  color: white;
}
