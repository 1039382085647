@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.title {
  font-family: var(--font-neue);
  font-weight: 500;
  @include RP(
    (
      "font-size": 18,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: left;
}

.formContainer {
  max-width: 520px;
}
