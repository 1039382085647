@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 100px;
  width: fit-content;
  background-color: $PRIMARY;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 50%;
  margin-left: -50px;
  font-family: "PN_SemiBold";
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $PRIMARY transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
