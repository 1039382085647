@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topLevelContainer {
  height: 100vh;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  flex-direction: column;
}

.mainContainer {
  background-color: white;
  overflow-y: auto;
  width: 100vw;
  display: flex;
}

.sideNavContainer {
  background-color: white;
  overflow-y: auto;
  height: 100%;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.sideNavFull {
  min-width: 256px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.sideNavHalf {
  min-width: 72px;
  max-width: 72px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  overflow-x: hidden;
}

.sideNavContainer::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.mainContainer::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.sideNavContainer::-webkit-scrollbar-thumb {
  background-color: #ff754c;
  border-radius: 100px;
  visibility: hidden;
}

.sideNavContainer::-webkit-scrollbar-track {
  border: 1px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
  background-color: #f0f3f6;
  visibility: hidden;
}

.showSideNavContainer::-webkit-scrollbar-thumb {
  visibility: visible !important;
}

.showSideNavContainer::-webkit-scrollbar-track {
  visibility: visible !important;
}

.child {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .showSideNavContainer::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .sideNavContainer {
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .sideNavFull {
    width: 72px;
    min-width: 72px;
  }
  .sideNavContainer {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sideNavFull {
    width: 72px;
    min-width: 72px;
  }
  .sideNavContainer {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .sideNavFull {
    width: 72px;
    min-width: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .sideNavFull {
    min-width: 256px;
  }
}

@media screen and (min-width: 1400px) {
  .sideNavFull {
    min-width: 256px;
  }
}
