@import "shared/utils/colors.scss";

.optionsContainer {
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  box-shadow: 9px 12px 16px 2px rgba(16, 24, 40, 0.1),
    0px 4px 6px 6px rgba(16, 24, 40, 0.05);
}

.labelStyle {
  font-size: 13px;
  font-family: var(--font-neue);
  font-weight: 400;
  color: $GRAY;
}

.iconStyle {
  height: 16px;
  width: 16px;
}

.optionContainer {
  border-bottom: 1px solid $BORDER;
  &:hover,
  &:hover > .labelStyle,
  &:hover > .iconStyle > path {
    background-color: #f2f7fb;
    color: $BLACK;
  }
}

.topradius {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.btmradius {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
