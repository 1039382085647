@import "shared/utils/colors.scss";
@import "shared/utils/responsive.module.scss";

.inputLabel {
  font-family: var(--font-neue);
  font-weight: 400;
  @include RP(
    (
      "font-size": 16,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: center;
}

.asterik {
  color: $PRIMARY;
}

.progress {
  font-weight: bold;
}
.error {
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  z-index: 1;
  margin-left: 5px;
}

.bookUploadContainer {
  height: 250px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 20px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;

  div {
    .bookUploadContent {
      .uploadText {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $PRIMARY;
        font-family: var(--font-neue);
      }

      .dragText {
        font-weight: 400;
        font-size: 13px;
        font-family: var(--font-neue);
        color: $SECONDARY;
      }

      p {
        font-weight: 400;
        font-size: 12px;
        color: $SECONDARY;
        font-family: var(--font-neue);
      }
    }
  }

  @media screen and (max-width: 409px) {
    height: 112px;

    div {
      .bookUploadContent {
        .uploadText,
        .dragText,
        p {
          font-size: 11px;
        }
      }
    }
  }
}
