@import "shared/utils/colors.scss";

.tabsContainer {
  position: relative;
  scroll-behavior: smooth;
  overflow-x: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  min-height: 31px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tabsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabsContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tab {
  position: relative;
  font-family: var(--font-neue);
  font-size: 15px;
  padding-bottom: 9px;
  white-space: nowrap;
  &:hover {
    cursor: pointer;
  }
}

.activeTab {
  font-weight: 500;
  color: $SECONDARY;
}

.inActiveTab {
  font-weight: 400;
  color: $BLACK;
}

.activeTabThumb {
  height: 3px;
  background-color: $SECONDARY;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.bottomBorder {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: $BORDER2;
  bottom: 0.5px;
}

@media screen and (max-width: 576px) {
  .tab {
    font-size: 14px;
    padding-bottom: 10px;
    min-height: 24px;
  }
}
