@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.title {
  font-family: var(--font-neue);
  font-weight: 500;
  @include RP(
    (
      "font-size": 18,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: left;
}

.profileContainer {
  .avtTitle {
    font-family: var(--font-neue);
    font-weight: 400;
    @include RP(
      (
        "font-size": 16,
        "line-height": 16.8,
      )
    );
    color: $BLACK;
    text-align: center;
  }

  .avtContainer {
    @include RP(
      (
        "height": 100,
        "width": 100,
      )
    );
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      @include RP(
        (
          "height": 100,
          "width": 100,
        )
      );
      border-radius: 100%;
      object-fit: cover;
    }
    .cameraContainer {
      position: absolute;
      right: 0;
      bottom: 0;
      @include RP(
        (
          "height": 36,
          "width": 36,
        )
      );
      background-color: $SECONDARY;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      svg {
        @include RP(
          (
            "height": 20,
            "width": 20,
          )
        );
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .error {
    width: fit-content;
    color: #ea5b5b;
    font-size: 11px;
    text-align: left;
    z-index: 1;
    margin-left: 5px;
  }
}
