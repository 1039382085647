@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.tableContainer {
  overflow-x: auto; /* Makes the table horizontally scrollable */
  width: "100%";
  @include RP(
    (
      "margin-top": 30,
    )
  );
}

.responsiveTable {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Ensures the table has a minimum width */
}

.responsiveTable th,
.responsiveTable td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
  max-width: 300px;
}

.responsiveTable th {
  background-color: $PRIMARY;
  color: white;
  text-align: center;
}

.editIcon,
.deleteIcon {
  cursor: pointer;
  padding: 5px;
}
.actionContainer {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background-color: $LIGHT_GRAY2;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: $PRIMARY;
    }
  }
}
