@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.mainContainer {
  padding: 30px 25px;
  border-radius: 8px;
  flex-grow: 1;
  flex-basis: 280px;
  .title {
    @include RP(
      (
        "font-size": 22,
      )
    );
    font-weight: bold;
    color: white;
  }
  label {
    @include RP(
      (
        "font-size": 15,
      )
    );
    color: white;
    padding-right: 10px;
  }
}
