@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.breadCrumbActive,
.breadCrumbDefault {
  font-style: normal;
  font-weight: 500;
  font-family: var(--font-neue);
  white-space: nowrap;
  @include RP(
    (
      "font-size": 16,
      "line-height": 21,
      "margin-left": 10,
    )
  );
}

.breadCrumbDefault {
  color: $GRAY;
  margin-right: 10px;
  @include RP(
    (
      "margin-right": 10,
    )
  );
}
