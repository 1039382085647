@import "../../../shared/utils/responsive.module.scss";
@import "../../../shared/utils/colors.scss";

.header {
  background-color: $PRIMARY;

  position: sticky;
  top: 0;

  .text {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 14px;

    .icon {
      @include RP(
        (
          "width": 100,
          "height": 70,
        )
      );
    }

    h2 {
      @include RP(
        (
          "font-size": 34,
        )
      );
      text-wrap: nowrap;
      text-align: center;
      justify-content: center;

      font-family: "Roboto", "Arial", sans-serif;
      color: #fff;

      //justify-content: center;
    }
  }
}

.main {
  p {
    @include RP(
      (
        "font-size": 16,
      )
    );
    color: #79797c;
    font-family: "Roboto", "Arial", sans-serif;
    text-align: justify;

    a {
      color: #002855;
      background-color: transparent;
      text-decoration: underline;
    }

    a:hover {
      color: #007aff;
    }
  }
}
