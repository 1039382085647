@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.mainContainer {
  @include RP(
    (
      "border-radius": 8,
      "padding": 30,
      "margin-top": 30,
    )
  );
  background-color: white;
  width: 95%;
  box-shadow: 0px 4px 24px 10px rgba(0, 0, 0, 0.05);
  margin: auto;

  .title {
    font-family: var(--font-neue);
    font-weight: 500;
    @include RP(
      (
        "font-size": 20,
        "line-height": 24,
        "margin-bottom": 30,
      )
    );

    color: $BLACK;
    text-align: left;
  }
}
